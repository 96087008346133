import React, { useState } from "react"
import api from "../services/api"
import { Formik } from "formik"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

import mapsIcon from "../images/icons/maps-red.svg"
import mailIcon from "../images/icons/mail-red.svg"
import phoneIcon from "../images/icons/phone-red.svg"

function ContatoPage({ location }) {
  const [emailSentSuccess, setEmailSentSuccess] = useState(false)

  async function handleSubmit(data) {
    //console.log(data)

    // const response =
    await api.post("/api/siteZoombe", data)

    //console.log(response)

    setEmailSentSuccess(true)
  }

  return (
    <Layout darkmode={true} location={location}>
      <SEO title="Contato" />
      <Container>
        <Wrapper>
          <SectionLeft>
            <h3>contato</h3>
            <h2>estamos prontos pra te atender</h2>
            <ul>
              <li>
                <span>
                  <img src={mailIcon} alt="mail" />
                </span>
                <a href="mailto:contato@zoombe.com.br">contato@zoombe.com.br</a>
              </li>
              <li>
                <span>
                  <img src={phoneIcon} alt="phone" />
                </span>
                <a href="tel:6236223632">62 3622-3632</a>
              </li>
              <li>
                <span>
                  <img src={phoneIcon} alt="phone" />
                </span>
                <a href="tel:6236423541">62 3642-3541</a>
              </li>
              <li>
                <span>
                  <img src={mapsIcon} alt="maps" />
                </span>
                <a href="https://goo.gl/maps/XRpZzYzMUeGByXr17">
                  Avenida C-255, 270, sala 1000, <br />
                  Swiss Office Tower, Setor Nova Suíça, <br />
                  Goiânia-GO, CEP 74280-010
                </a>
              </li>
            </ul>
          </SectionLeft>
          <SectionRight>
            <h2>Envie uma mensagem</h2>

            {emailSentSuccess === false ? (
              <Formik
                initialValues={{
                  email: "",
                  nome: "",
                  telefone: "",
                  mensagem: "",
                }}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <input
                      placeholder="Seu nome"
                      id="nome"
                      type="text"
                      name="nome"
                      value={values.nome}
                      onChange={handleChange}
                      required
                    />

                    <input
                      placeholder="E-mail"
                      id="email"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      required
                    />

                    <input
                      placeholder="Telefone ou celular"
                      id="telefone"
                      type="text"
                      name="telefone"
                      value={values.telefone}
                      onChange={handleChange}
                      required
                    />

                    <textarea
                      id="mensagem"
                      name="mensagem"
                      rows="4"
                      cols="50"
                      placeholder="Mensagem"
                      onChange={handleChange}
                      value={values.mensagem}
                    />

                    <button type="submit">Enviar mensagem</button>
                  </form>
                )}
              </Formik>
            ) : (
              <p>
                Mensagem enviada! <br /> Agradecemos o seu contato :)
              </p>
            )}
          </SectionRight>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 969px;
  background-color: #212121;
`

const Wrapper = styled.div`
  padding-top: 50px;
  padding-left: 150px;
  max-width: 1200px;
  margin: 0 auto;

  padding-bottom: 60px;

  display: flex;
  justify-content: space-between;

  align-items: flex-start;

  @media (max-width: 1050px) {
    flex-direction: column;
  }

  @media (max-width: 1050px) {
    padding-left: 20px;
  }
`

const SectionLeft = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2,
  h3,
  p,
  li {
    color: white;
    font-weight: 400;
  }

  h3 {
    color: #f44336;
    margin-bottom: 20px;
    font-size: 57px;
  }

  h2 {
    font-size: 65px;
    line-height: 70px;
    margin-bottom: 40px;
    max-width: 567px;
  }

  li {
    font-size: 22px;
    display: flex;
    align-items: center;

    span img {
      margin-top: 5px;
      margin-right: 10px;
    }
  }
  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: #f44336;
    }
  }

  @media (max-width: 499px) {
    li {
      font-size: 18px;
    }
    h3 {
      margin-bottom: 0px;
      font-size: 30px;
    }

    h2 {
      font-size: 40px;
      line-height: 42px;
    }
  }

  @media (max-width: 320px) {
    h2 {
      font-size: 38px;
      line-height: 42px;
    }
  }
`

const SectionRight = styled.section`
  display: flex;
  flex-direction: column;

  max-width: 520px;

  padding-top: 270px;
  padding-right: 200px;

  h2 {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 40px;
    color: white;
    font-weight: 400;
  }

  p {
    color: #f44336;
    font-size: 18px;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 446px;

    input {
      background-color: transparent;
      padding: 10px 20px;
      font-size: 18px;
      border: 1px solid white;
      border-radius: 10px;
      color: white;

      & + input {
        margin-top: 20px;
      }
    }

    textarea {
      margin-top: 20px;
      background-color: transparent;
      padding: 10px 20px;
      font-size: 18px;
      border: 1px solid white;
      border-radius: 10px;
      color: white;
    }

    button {
      background-color: #f44336;
      color: white;
      font-weight: 500;
      margin-top: 20px;
      align-self: flex-end;
      border: none;
      border-radius: 20px;
      padding: 10px 30px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1050px) {
    padding-top: 40px;
    padding-right: 0px;
  }

  @media (max-width: 499px) {
    max-width: 100%;
    padding-right: 20px;
  }
`

export default ContatoPage
